<template>
  <layout-sub name="prize">
    <b-row class="p-prize">
      <b-col v-for="(el, index) in list" :key="index" cols="12" md="6" xl="4">
        <b-card
          header-class="p-prize-header p-0 border-bottom-0"
          class="mb-3 mb-lg-4 overflow-hidden"
        >
          <template #header> <img :src="el.thumb" /> </template>
          <b-card-text>{{ el.description }}</b-card-text>
          <template #footer>
            <div class="text-right">
              <small class="text-muted">{{ el.image_time }}</small>
            </div>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </layout-sub>
</template>

<script>
import LayoutSub from "@/components/LayoutSub";
import { fatchCurrent } from "@/api";

export default {
  name: "Prize",
  components: { LayoutSub },
  data() {
    return {
      list: [],
    };
  },
  mounted() {
    // 获取当前页面内容
    this.init();
  },
  methods: {
    async init() {
      const { data } = await fatchCurrent(22);
      if (data) {
        this.list = data;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.p-prize {
  &-header {
    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {
    &-header {
      img {
        position: relative;
        left: 50%;
        width: auto;
        height: 400px;
        transform: translateX(-50%);
      }
    }

    .card-text {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      overflow: hidden;
      height: 84px;
    }
  }
}
</style>
